import * as Sentry from "@sentry/browser";
import { fromEntries, isSupportedBrowser } from "@solvari/common-fe/helpers";

function initSentry() {
  Sentry.init({
    environment: window.siteConfig?.env || "production",
    release: `expert-sites-${import.meta.env.npm_package_version}`,
    dsn: "https://a7c497b1b40549048f2d61c246eb6ea3@o1412996.ingest.sentry.io/4504253747429376",
    // See: https://docs.sentry.io/clients/javascript/tips/
    ignoreErrors: [
      // Untraceable error: https://github.com/getsentry/sentry-javascript/issues/3440
      "Non-Error promise rejection captured",
      // Headless chrome issue
      /Blocked a frame with origin ".+" from accessing a cross-origin frame/,
      // Chrome on IOS bug, unfixable https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
      "__gCrWeb.edgeImmersiveReaderDOM.retrieveFeatures",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Avast extension error
      "_avast_submit",
    ],
    denyUrls: [
      // Solvari apps have their own Sentry instance
      "https://cdn.solvari.nl/apps",
      // Google Adsense
      /pagead\/js/i,
      // Browser extensions
      /extension:\/\//,
      // Safari extension bugs: https://github.com/getsentry/sentry-javascript/discussions/5875
      /@webkit-masked-url/,
    ],
    beforeSend(event, hint) {
      // Tags can be used to search or filter events
      event.tags = event.tags || {};
      event.tags = {
        ...event.tags,
        ...toContext({
          domain: window.location.hostname,
          themeId: window.siteConfig?.themeId,
          locale: window.siteConfig?.locale,
        }),
      };

      event.contexts = event.contexts || {};

      event.contexts.dataLayer = toContext({ events: window.dataLayer });

      if (hint.originalException) {
        event.contexts.originalException = toContext(hint.originalException);
      }

      return event;
    },
  });
}

function toContext(values: unknown, depth = 0): any {
  if (depth >= 5) {
    return values;
  }

  if (Array.isArray(values)) {
    return values.map((value) => toContext(value, depth + 1));
  }

  if (typeof values === "object" && values !== null) {
    return fromEntries(
      Object.entries(values).map(([key, value]) => {
        const escapedKey = key === "title" ? "_title" : key;
        return [escapedKey, toContext(value, depth + 1)];
      })
    );
  }

  return String(values);
}

if (isSupportedBrowser()) {
  initSentry();
}
